import { Flex, Input, Text } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import type { PatientInputType } from 'types/patients'
import type { FormType } from 'types/types'

import Card from 'components/Card'
import {
  ControlledDatePicker,
  ControlledSelect,
  ControlledToggle,
  Form,
} from 'components/Form'
import {
  DocumentType,
  Gender,
  PatientStage,
  PatientTypeEnum,
} from 'utils/enums'
import { enum2SelectOptions } from 'utils/forms'
import { documentTypeLabels, patientTypeLabels } from 'utils/labels'

export enum PatientFields {
  AGE = 'age',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  DATE_OF_BIRTH = 'dateOfBirth',
  EXTERNAL_PATIENT_ID = 'salesforceId',
  PHONE_NUMBER = 'phoneNumber',
  DOCUMENT_NUMBER = 'documentNumber',
  DOCUMENT_TYPE = 'documentType',
  CONSENT_TO_SMS = 'hasPatientConsentToSMS',
  HOME_PHONE = 'homePhone',
  COUNTRY = 'country',
  STREET = 'street',
  STATE = 'state',
  CITY = 'city',
  POSTAL_CODE = 'postalCode',
  GENDER = 'gender',
  CONCIERGE_NAME = 'conciergeName',
  CONCIERGE_PHONE_NUMBER = 'conciergePhoneNumber',
  PATIENT_IDENTIFIER = 'patientIdentifier',
  ADVOCATE_NAME = 'advocateName',
  ADVOCATE_PHONE_NUMBER = 'advocatePhoneNumber',
  STAGE = 'stage',
  PATIENT_TYPE = 'type',
}

const PatientForm = ({
  form,
  isLoading,
  ...props
}: FormType<PatientInputType>) => {
  const { t } = useTranslation()

  return (
    <Card isLoading={isLoading}>
      <Form<PatientInputType> form={form} {...props}>
        <Flex
          direction="column"
          align="start"
          gap="xlg"
          className="form-content"
        >
          <Text variant="h2">{t('Patient Details')}</Text>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <Input
              {...form.register(PatientFields.FIRST_NAME)}
              label={t('First Name')}
              placeholder={t('Enter first name')}
              state={
                form.formState.errors[PatientFields.FIRST_NAME]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[PatientFields.FIRST_NAME]?.message
              }
              fullWidth
            />
            <Input
              {...form.register(PatientFields.LAST_NAME)}
              label={t('Last Name')}
              placeholder={t('Enter last name')}
              state={
                form.formState.errors[PatientFields.LAST_NAME]
                  ? 'error'
                  : 'null'
              }
              errorMsg={form.formState.errors[PatientFields.LAST_NAME]?.message}
              fullWidth
            />
          </Flex>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <Input
              {...form.register(PatientFields.AGE)}
              label={t('Age')}
              placeholder={t('Enter Age')}
              state={
                form.formState.errors[PatientFields.AGE] ? 'error' : 'null'
              }
              errorMsg={form.formState.errors[PatientFields.AGE]?.message}
              fullWidth
            />
            <Input
              {...form.register(PatientFields.EMAIL)}
              label={t('Email')}
              placeholder={t('Enter email')}
              state={
                form.formState.errors[PatientFields.EMAIL] ? 'error' : 'null'
              }
              errorMsg={form.formState.errors[PatientFields.EMAIL]?.message}
              fullWidth
            />
          </Flex>
          <Input
            {...form.register(PatientFields.EXTERNAL_PATIENT_ID)}
            label={t('Salesforce ID')}
            placeholder={t('Enter Salesforce ID')}
            state={
              form.formState.errors[PatientFields.EXTERNAL_PATIENT_ID]
                ? 'error'
                : 'null'
            }
            errorMsg={
              form.formState.errors[PatientFields.EXTERNAL_PATIENT_ID]?.message
            }
            fullWidth
          />
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <Input
              {...form.register(PatientFields.PHONE_NUMBER)}
              type="tel"
              label={t('Mobile Phone')}
              placeholder={t('Enter mobile phone number')}
              state={
                form.formState.errors[PatientFields.PHONE_NUMBER]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[PatientFields.PHONE_NUMBER]?.message
              }
              fullWidth
            />
            <Input
              {...form.register(PatientFields.HOME_PHONE)}
              type="tel"
              label={t('Home Phone')}
              placeholder={t('Enter home phone number')}
              state={
                form.formState.errors[PatientFields.HOME_PHONE]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[PatientFields.HOME_PHONE]?.message
              }
              fullWidth
            />
          </Flex>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <Input
              {...form.register(PatientFields.DOCUMENT_NUMBER)}
              label={t('Document Number')}
              placeholder={t('Enter document number')}
              state={
                form.formState.errors[PatientFields.DOCUMENT_NUMBER]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[PatientFields.DOCUMENT_NUMBER]?.message
              }
              fullWidth
            />
            <ControlledSelect
              name={PatientFields.DOCUMENT_TYPE}
              options={enum2SelectOptions(DocumentType, documentTypeLabels)}
              label={t('Document Type')}
              placeholder={t('Select document type')}
              state={
                form.formState.errors[PatientFields.DOCUMENT_TYPE]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[PatientFields.DOCUMENT_TYPE]?.message
              }
              fullWidth
            />
          </Flex>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <Input
              {...form.register(PatientFields.COUNTRY)}
              label={t('Country')}
              placeholder={t('Enter country')}
              state={
                form.formState.errors[PatientFields.COUNTRY] ? 'error' : 'null'
              }
              errorMsg={form.formState.errors[PatientFields.COUNTRY]?.message}
              fullWidth
            />
            <Input
              {...form.register(PatientFields.CITY)}
              label={t('City')}
              placeholder={t('Enter city')}
              state={
                form.formState.errors[PatientFields.CITY] ? 'error' : 'null'
              }
              errorMsg={form.formState.errors[PatientFields.CITY]?.message}
              fullWidth
            />
          </Flex>
          <Input
            {...form.register(PatientFields.STREET)}
            label={t('Street')}
            placeholder={t('Enter street')}
            state={
              form.formState.errors[PatientFields.STREET] ? 'error' : 'null'
            }
            errorMsg={form.formState.errors[PatientFields.STREET]?.message}
            fullWidth
          />
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <Input
              {...form.register(PatientFields.STATE)}
              label={t('State')}
              placeholder={t('Enter state')}
              state={
                form.formState.errors[PatientFields.STATE] ? 'error' : 'null'
              }
              errorMsg={form.formState.errors[PatientFields.STATE]?.message}
              fullWidth
            />
            <Input
              {...form.register(PatientFields.POSTAL_CODE)}
              label={t('Postal Code')}
              placeholder={t('Enter postal code')}
              state={
                form.formState.errors[PatientFields.POSTAL_CODE]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[PatientFields.POSTAL_CODE]?.message
              }
              fullWidth
            />
          </Flex>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <ControlledDatePicker
              name={PatientFields.DATE_OF_BIRTH}
              label={t('Date of Birth')}
              error={
                form.formState.errors[PatientFields.DATE_OF_BIRTH]?.message
              }
            />
            <ControlledSelect
              name={PatientFields.GENDER}
              options={enum2SelectOptions(Gender)}
              label={t('Gender')}
              placeholder={t('Select gender')}
              state={
                form.formState.errors[PatientFields.GENDER] ? 'error' : 'null'
              }
              errorMsg={form.formState.errors[PatientFields.GENDER]?.message}
              fullWidth
            />
          </Flex>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <Input
              {...form.register(PatientFields.CONCIERGE_NAME)}
              label={t('Concierge Name')}
              placeholder={t('Enter concierge name')}
              state={
                form.formState.errors[PatientFields.CONCIERGE_NAME]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[PatientFields.CONCIERGE_NAME]?.message
              }
              fullWidth
            />
            <Input
              {...form.register(PatientFields.CONCIERGE_PHONE_NUMBER)}
              type="tel"
              label={t('Concierge Phone Number')}
              placeholder={t('Enter concierge phone number')}
              state={
                form.formState.errors[PatientFields.CONCIERGE_PHONE_NUMBER]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[PatientFields.CONCIERGE_PHONE_NUMBER]
                  ?.message
              }
              fullWidth
            />
          </Flex>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <Input
              {...form.register(PatientFields.ADVOCATE_NAME)}
              label={t('Advocate Name')}
              placeholder={t('Enter advocate name')}
              state={
                form.formState.errors[PatientFields.ADVOCATE_NAME]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[PatientFields.ADVOCATE_NAME]?.message
              }
              fullWidth
            />
            <Input
              {...form.register(PatientFields.ADVOCATE_PHONE_NUMBER)}
              type="tel"
              label={t('Advocate Phone Number')}
              placeholder={t('Enter advocate phone number')}
              state={
                form.formState.errors[PatientFields.ADVOCATE_PHONE_NUMBER]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[PatientFields.ADVOCATE_PHONE_NUMBER]
                  ?.message
              }
              fullWidth
            />
          </Flex>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <ControlledSelect
              name={PatientFields.STAGE}
              options={enum2SelectOptions(PatientStage)}
              label={t('Stage')}
              placeholder={t('Select stage')}
              state={
                form.formState.errors[PatientFields.STAGE] ? 'error' : 'null'
              }
              errorMsg={form.formState.errors[PatientFields.STAGE]?.message}
              fullWidth
            />
            <ControlledSelect
              name={PatientFields.PATIENT_TYPE}
              options={enum2SelectOptions(PatientTypeEnum, patientTypeLabels)}
              label={t('Patient Type')}
              placeholder={t('Select patient type')}
              state={
                form.formState.errors[PatientFields.PATIENT_TYPE]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[PatientFields.PATIENT_TYPE]?.message
              }
              fullWidth
            />
          </Flex>
          <ControlledToggle
            name={PatientFields.CONSENT_TO_SMS}
            label={t('Consents to SMS Messaging')}
          />
        </Flex>
      </Form>
    </Card>
  )
}

export default PatientForm
